import React from "react";
import { gql } from "@apollo/client";
import { CreatePaginationQuery, FragmentPage, FragmentPost } from "../node";
import { useSearchContext } from "./Context";
import SearchForm from "./SearchForm";
import Node from "../node";

export function Search() {
  const { filter } = useSearchContext();

  const Query = gql`
    query Search(
      $filter: String!
      $first: Int
      $last: Int
      $after: String
      $before: String
    ) {
      ${CreatePaginationQuery(
        "contentNodes",
        `
          __typename
          ...PostFragment
          ...PageFragment
        `,
        "status: PUBLISH, search: $filter"
      )}
    }
    ${FragmentPage}
    ${FragmentPost}
  `;

  return (
    <Node
      isArchive
      query={Query}
      nodeTitle="Search"
      variables={{ filter }}
      skip={filter.length < 4}
      wrap={SearchForm}
    />
  );
}

export default Search;
