import React from "react";
import { PageWidth, Title, PageLoading } from "../components";
import PostContent from "./PostContent";

export function Single({ node, loading }) {
  const { title, content, name, classifiedField } = node;

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <Title>{title || name}</Title>
      <PageWidth>
        {classifiedField?.expiration && (
          <div className="mb3 gray ttu f7 tr">
            expires {classifiedField.expiration}
          </div>
        )}
        <PostContent>{content}</PostContent>
      </PageWidth>
    </>
  );
}

export default Single;
