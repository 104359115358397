import React from "react";
import { KeyHole } from "../svg";

export const PageLoading = () => {
  return (
    <div
      className="bg-secondary black flex items-center justify-center"
      style={{ minHeight: "65vh" }}
    >
      <div className="animate__bounce animate__animated animate__infinite">
        <KeyHole width={54} />
      </div>
    </div>
  );
};
