import React from "react";
import StickyPosts from "./StickyPosts";
import { PostContent, Seo, useNode } from "../node";
import { PageLoading, PageWidth } from "../components";
import { SidebarAd, BannerAd } from "../ads";

export function Home() {
  const { loading, node, acf } = useNode({ mainQuery: true });

  const social = acf?.homeFields?.social;
  const issuu = acf?.homeFields?.issuu;

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PageWidth>
      <Seo {...(node?.seo || {})} />

      <StickyPosts className="mv4-l mv3" />

      <div className="overflow-hidden">
        <div className="flex-l items-stretch-l mv4-l mv3 nl3 nr3">
          <div className="w-two-thirds-l ph3">
            <div className="h-100 bg-dark-gray mb3 mb0-l">
              <div className="bg-white cf">
                <BannerAd />
              </div>
              {issuu && <div dangerouslySetInnerHTML={{ __html: issuu }} />}
            </div>
          </div>
          <div className="w-third-l ph3 flex flex-column">
            {social && <div dangerouslySetInnerHTML={{ __html: social }} />}

            <SidebarAd className="mt3" />
          </div>
        </div>
      </div>

      <PostContent>{node.content}</PostContent>
    </PageWidth>
  );
}

export default Home;
