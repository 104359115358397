import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { FragmentPost } from "../node";

export const useStickyPosts = () => {
  const OnlySticky = gql`
  query Sticky {
    sticky: posts(first: 3, where: { onlySticky: true, status: PUBLISH }) {
      nodes {
        ...PostFragment
      }
    }
    fallback: posts(first: 3, where: { status: PUBLISH }) {
      nodes {
        ...PostFragment
      }
    }
  }
  ${FragmentPost}
`;

  const { data, loading } = useQuery(OnlySticky, { errorPolicy: "all" });

  const posts = useMemo(() => {
    const sticky = data?.sticky?.nodes || [];
    const fallback = data?.fallback?.nodes || [];
    if (sticky.length < 3) {
      return [...sticky, ...fallback].slice(0, 3);
    } else {
      return [...sticky];
    }
  }, [data]);

  return { posts, loading };
};
