import React from "react";
import Card from "./Card";

export function Archive(props) {
  const { edges, loading } = props;

  return (
    <div className="overflow-hidden">
      <div className="flex nl3 nr3 nt3 nb3 flex-wrap">
        {~~edges?.length > 0 &&
          edges.map(({ node, cursor }) =>
            node.databaseId ? (
              <Card
                key={cursor}
                className="pa3 w-third-l"
                {...{ loading, node, cursor }}
              />
            ) : null
          )}
      </div>
    </div>
  );
}

export default Archive;
