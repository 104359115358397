import React from "react";
import { Card } from "../node";
import { useStickyPosts } from "../hooks";

export function StickyPosts({ className }) {
  const { posts } = useStickyPosts();

  return (
    <div {...{ className }}>
      <div className="overflow-hidden">
        <div className="flex-l nl3 nr3">
          {posts.map((node) => (
            <Card className="ph3 w-100 w-third-l" key={node.id} {...{ node }} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default StickyPosts;
