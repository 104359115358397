export default function createGallery(htmlelement) {
  const anchors = [...htmlelement.querySelectorAll("a")];

  function Listener(event) {
    event.preventDefault();
    let lightbox;

    const getAnchors = () => {
      const img = lightbox.querySelector("img");
      const src = img.src;
      let nextanchor = anchors[0];
      let prevanchor = anchors[anchors.length - 1];

      for (let i = 0; i < anchors.length; i++) {
        const z = anchors[i].querySelector("img");

        if (z.src === src) {
          const n = anchors[i + 1];
          if (n) {
            nextanchor = n;
          }

          const p = anchors[i - 1];
          if (p) {
            prevanchor = p;
          }
        }
      }

      return { next: nextanchor, prev: prevanchor, src };
    };

    const next = () => {
      const a = getAnchors();

      lightbox.innerHTML = `<img src="${a.next.querySelector("img").src}" />`;
    };

    const prev = () => {
      const a = getAnchors();

      lightbox.innerHTML = `<img src="${a.prev.querySelector("img").src}" />`;
    };

    lightbox = EnsureLightbox(next, prev);

    const target = event.currentTarget;
    const image = target.querySelector("img");

    if (!image) return;

    const src = image.src;

    lightbox.innerHTML = `<img src="${src}" />`;
  }

  if (!anchors) {
    return () => {};
  }

  for (const a of anchors) {
    a.addEventListener("click", Listener);
  }

  return () => {
    for (const a of anchors) {
      a.removeEventListener("click", Listener);
    }
  };
}

function EnsureLightbox(nextFn = () => {}, prevFn = () => {}) {
  let lightbox = document.querySelector(".lightbox-gallery-2023");

  if (!lightbox) {
    const next = document.createElement("button");
    const prev = document.createElement("button");

    const btnclasses = [
      "bn",
      "mt0",
      "mb0",
      "ma0",
      "bg-gray",
      "pa3",
      "white",
      "fw7",
      "pointer",
    ];
    prev.classList.add(...btnclasses, "ml0", "mr3");
    next.classList.add(...btnclasses, "mr0", "ml3");

    next.textContent = "Next";
    prev.textContent = "Prev";

    next.addEventListener("click", nextFn);
    prev.addEventListener("click", prevFn);

    const wrapperlightbox = document.createElement("div");
    wrapperlightbox.classList.add(
      "fixed",
      "absolute--fill",
      "flex",
      "justify-center",
      "items-center",
      "z-5"
    );

    const wrapperb = document.createElement("div");
    wrapperb.classList.add("w-70", "flex", "justify-center");

    lightbox = document.createElement("div");
    lightbox.classList.add(
      "lightbox-gallery-2023",
      "pa3",
      "bg-white",
      "br3",
      "ba",
      "b--dark-gray"
    );

    lightbox.addEventListener("click", (event) => {
      event.stopPropagation();

      wrapperlightbox.remove();
    });

    document.body.appendChild(wrapperlightbox);
    wrapperlightbox.appendChild(prev);
    wrapperlightbox.appendChild(wrapperb);
    wrapperb.appendChild(lightbox);
    wrapperlightbox.appendChild(next);
  }

  return lightbox;
}
