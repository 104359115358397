import React, { useState } from "react";
import Menu from "./Menu";
import { SearchIcon } from "../svg";
import { ReactComponent as Bars } from "./bars.svg";
import { useHistory, useLocation } from "react-router-dom";
import { useSettings } from "../hooks";
import { Input, Social } from "../components";
import { Logo } from "./Logo";
import { useSearchContext } from "../search";

export function Header() {
  const { setFilter, filter } = useSearchContext();
  const [open, setOpen] = useState();
  const { description } = useSettings();
  const history = useHistory();
  const { pathname } = useLocation();

  const onSearch = (value) => {
    setFilter(value);

    if (pathname !== "/search") {
      history.push("/search");
    }
  };

  return (
    <header id="header">
      <div className="relative z-1 pa3 ph0-l flex items-center justify-center">
        <div className="w-25-l" />

        <div className="w-100 w-50-l tc">
          <div className="dib">
            <Logo />
          </div>
        </div>

        <div className="db dn-l pointer mh3" onClick={() => setOpen(!open)}>
          <Bars />
        </div>

        <div className="dn db-l w-25-l flex items-end">
          <div className="flex items-center mr3">
            <div className="flex-auto">
              <Input
                icon={<SearchIcon width={16} className="fill-gray" />}
                type="search"
                value={filter}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>

            <Social className="dib ml4 flex-none" />
          </div>
        </div>
      </div>

      <div className="bg-secondary black tc pv1 fw7 f6 ttu">{description}</div>
      <nav className="tc mt2-l bg-primary white bg-transparent-l black-l">
        <Menu
          {...{ open, setOpen }}
          location="HEADER_MENU"
          className="ma0 fw7 f4 ttu"
        />
      </nav>
    </header>
  );
}

export default Header;
