import { HttpLink, ApolloLink } from "@apollo/client";

export const authAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // If we get an error, log the error.
    if (response?.errors?.length > 0) {
      console.error(response.errors);
    }

    return response;
  });
});

export const link = new HttpLink({
  uri: `${process.env.REACT_APP_DOMAIN}/graphql`,
});
