import React, { createContext, useContext, useReducer, useRef } from "react";

/**
 * Node Updater Ref Context.
 *
 * Used to prevent an extra render in useNode during setting.
 */
const NodeUpdaterContext = createContext({});
export const useNodeUpdaterContext = () => useContext(NodeUpdaterContext);

/**
 * Internal Node Context.
 *
 * Holds the edges, loading, node, etc. of the main query.
 * Set via the updater context.
 */
const NodeContext = createContext({ node: {} });
export const useNodeContext = () => useContext(NodeContext);

/**
 * Internal Provider.
 *
 * @param {Object} prop React Property attrs.
 * @param {Object} prop.children The children of the React component.
 */
function InternalNodeProvider({ children }) {
  const { updater } = useNodeUpdaterContext();
  const [state, updateNode] = useReducer(Reducer, initialState);

  updater.current.updateNode = updateNode;

  return (
    <NodeContext.Provider
      value={{
        ...state,
        updateNode,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
}

/**
 * External Provider.
 *
 * Exposes the updater ref to the internal provider.
 *
 * @param {Object} prop React Property attrs.
 * @param {Object} prop.children The children of the React component.
 */
export function NodeProvider({ children }) {
  const updater = useRef({});

  return (
    <NodeUpdaterContext.Provider value={{ updater }}>
      <InternalNodeProvider>{children}</InternalNodeProvider>
    </NodeUpdaterContext.Provider>
  );
}

/**
 * State reducer. Setup so that all props passed in second arg will be deconstructed into the state.
 *
 * @param {Object} state Context state. {node, edges, loading, etc}.
 * @param {Object} props Props injected into state.
 */
function Reducer(state, props) {
  if (props.action === "RESET") {
    return initialState;
  }

  const updates = {};

  Object.entries(props).forEach(([k, v]) => {
    if (v !== undefined) {
      updates[k] = v;
    }
  });

  return { ...state, ...updates };
}

/**
 * @constant {Object} initialState Starting properties intended to pass through context.
 */
const initialState = {
  node: {},
  edges: [],
  loading: false,
};
