import React from "react";
import { PostContent, Seo, useNode } from "../node";
import { PageWidth, Title } from "../components";
import Form from "../form-default";

export function Contact() {
  const { node } = useNode({ mainQuery: true });
  const { title, content, seo } = node;

  return (
    <>
      <Seo {...seo} />
      <Title>{title}</Title>
      <PageWidth>
        <div className="w-60-l center mb4">
          <Form />
        </div>

        <PostContent>{content}</PostContent>
      </PageWidth>
    </>
  );
}
