export const Valid = {
  NotEmptyString: (v) => (v || "") !== "",
  Email: (v) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v || ""),
  Phone: (v) =>
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v || ""),
  LessThan: (amount) => {
    return (v) => {
      return (v || "").length < amount;
    };
  },
  GreaterThan: (amount) => {
    return (v) => {
      return (v || "").length > amount;
    };
  },
  MinWords: (amount) => {
    return (v) => {
      return (v || "").replace(/\s\s+/g, " ").split(" ").length > amount - 1;
    };
  },
  NotEmpty: (v) => (v || "") !== "" && (v || [])?.length !== 0,
  OnlyOne: (v) => (v || []).length === 1,
};

export default Valid;
