import React from "react";
import { Anchor } from "../components";
import { useBannerAd } from "./useBannerAd";

export default function BannerAd() {
  const { src, alt, link } = useBannerAd();

  if (!src) {
    return null;
  }

  return (
    <div className="mb3">
      <Wrap {...{ link }}>
        <img className="w-100 db object-cover" {...{ src, alt }} />
      </Wrap>
    </div>
  );
}

function Wrap({ link, children }) {
  if (link) {
    return <Anchor href={link}>{children}</Anchor>;
  }

  return children;
}
