import React from "react";
import { gql, useQuery } from "@apollo/client";

export function Social({ className }) {
  const { data } = useQuery(SocialQuery, {
    errorPolicy: "all",
  });

  const links = data?.menuItems?.nodes || [];

  if (links.length === 0) {
    return null;
  }

  return (
    <div {...{ className }}>
      <div className="nl1 nr1">
        {links.map(({ id, url, label, field }) => (
          <a href={url} key={id} className="dib mh1">
            {field?.icon ? <Icon {...field.icon} alt={label} /> : label}
          </a>
        ))}
      </div>
    </div>
  );
}

const SocialQuery = gql`
  query SocialMenu {
    menuItems(where: { location: SOCIAL }) {
      nodes {
        id
        url
        label
        field: menuItemField {
          icon {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

const Icon = ({ mediaItemUrl, alt }) => {
  return (
    <div className="br-100 dib bg-white b--black b--hover-secondary ba bw1 pa2">
      <div
        style={{ backgroundImage: `url("${mediaItemUrl}")` }}
        className="bg-center contain w2 h2"
        aria-label={alt}
      />
    </div>
  );
};

export default Social;
