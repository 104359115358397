import React from "react";
import DefaultFormProvider from "./Context";
import Form from "./Form";

export default function DefaultForm(props) {
  return (
    <DefaultFormProvider>
      <Form {...props} />
    </DefaultFormProvider>
  );
}
