import React from "react";

export function Main({ children }) {
  return (
    <div className="main lh-copy relative z-1 flex-auto">
      <div className="mv4">{children}</div>
    </div>
  );
}

export default Main;
