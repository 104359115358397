import { useMemo } from "react";
import { useNodeContext } from "../node";
import { useSettings } from "../hooks";

export function useSidebarAd() {
  const { node } = useNodeContext();
  const { sidebarAdLink: defaultLink, sidebarAdImage } = useSettings();
  const { defaultSrc, defaultAlt } = useMemo(() => {
    return {
      defaultSrc: sidebarAdImage?.node?.sourceUrl,
      defaultAlt: sidebarAdImage?.node?.altText,
    };
  }, [sidebarAdImage]);

  const link = useMemo(() => {
    let x = node.sidebarAd?.link;

    return x ? x : defaultLink;
  }, [node, defaultLink]);

  const { src, alt } = useMemo(() => {
    let x = node.sidebarAd?.image?.src;
    let y = node.sidebarAd?.image?.alt;

    return { src: x ? x : defaultSrc, alt: y ? y : defaultAlt };
  }, [node, defaultSrc, defaultAlt]);

  return { link, src, alt, enabled: !node.sidebarAd?.disabled };
}
