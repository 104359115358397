import React, { forwardRef, useRef, useEffect, useMemo } from "react";
import { useAppContext } from "../Context";
import { useNodeContext } from "../node";
import { KeyHole } from "../svg";
import { BannerAd } from "../ads";

let Title = (
  {
    notHeading,
    wrap = "h1",
    className = "mb3",
    children,
    stickied,
    subtitle,
    ...props
  },
  ref
) => {
  const { title } = useAppContext();
  const Wrap = notHeading ? "div" : wrap;
  const titleEle = useRef({});
  const backgroundEle = useRef({});
  const blueExtensionEle = useRef({});

  const subTitle = useMemo(
    () => (stickied ? <Stickied /> : <Meta />),
    [stickied]
  );

  useEffect(() => {
    const titleHeight = !!titleEle.current.getBoundingClientRect
      ? titleEle.current.getBoundingClientRect().height
      : 0;

    if (title.current.setTitleHeight) {
      title.current.setTitleHeight(titleHeight);
    }
  }, [titleEle, title, subTitle]);

  useEffect(() => {
    let timeout;

    const updateHeight = () => {
      if (backgroundEle.current && blueExtensionEle.current) {
        blueExtensionEle.current.style.height = `${
          backgroundEle.current.getBoundingClientRect().height
        }px`;
      }
    };

    const u = () => {
      clearTimeout(timeout);
      timeout = setTimeout(updateHeight, 500);
    };

    updateHeight();
    window.addEventListener("resize", u);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", u);
    };
  }, [backgroundEle, blueExtensionEle]);

  return (
    <>
      <BannerAd />
      <div {...{ className }} {...props} ref={titleEle}>
        <div
          className="bg-blue absolute left-0 db"
          style={{ width: "50vw" }}
          ref={blueExtensionEle}
        />
        <div className="relative z-2 bg-blue ph2" ref={backgroundEle}>
          <Wrap className="secondary mb3 mt0 ma0-l lh-title">
            <span className="f2 i fw4" {...{ ref }}>
              {children || <span>&nbsp;</span>}
            </span>
          </Wrap>
        </div>
        {subTitle ? (
          <div className="relative z-1 flex-l">{subTitle}</div>
        ) : null}
      </div>
    </>
  );
};

function Stickied() {
  return (
    <div className="b--black bg-secondary pa2">
      <div className="flex items-center">
        <KeyHole width={28} className="fill-black" />
        <div className="ttu lh-title fw7 f7 ml2">
          <div>Featured</div>
          <div>Content</div>
        </div>
      </div>
    </div>
  );
}

function Meta() {
  const {
    node: { author, date },
  } = useNodeContext();

  if (!date) return null;

  return (
    <div className="tr-l tc mt2 mt0-l ml-auto-l f6 gray ttu">
      {author ? (
        <React.Fragment>
          <div className="dib">{`${author.node.name}`}</div>
          <span className="mh2 dib">&bull;</span>
        </React.Fragment>
      ) : null}
      <div className="dib">{date}</div>
    </div>
  );
}

Title = forwardRef(Title);
export { Title };
