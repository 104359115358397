import React, { useEffect, useRef, useState } from "react";
import MenuItem from "./MenuItem";
import useMenu from "./useMenu";
import { useLocation } from "react-router-dom";
import { PageWidth } from "../components";
import { useUiContext } from "../Context";

const hasAdminBar = !!document.getElementById("wpadminbar");

export function Menu({ className, open, setOpen }) {
  const [hover, setHover] = useState("");
  const { isDesktop } = useUiContext();
  const { pathname } = useLocation();
  const { menuItems } = useMenu({ location: "HEADER_MENU" });
  const prevPath = useRef();

  const DrawerBackground = { className: "relative-l z-2", style: {} };
  const MobileDrawer = { className: "", style: {} };

  /**
   * Closes menu on path change.
   */
  useEffect(() => {
    if (pathname !== prevPath.current) {
      setOpen(false);
    }
  }, [pathname, setOpen]);

  /**
   * Updates the prevPath when pathname changes.
   */
  useEffect(() => {
    prevPath.current = pathname;
  }, [pathname]);

  if (open) {
    DrawerBackground.className += " db";
  } else {
    DrawerBackground.className += " dn db-l";
  }

  if (!isDesktop) {
    DrawerBackground.className +=
      " bg-white-30 absolute absolute--fill pointer";

    if (hasAdminBar) {
      DrawerBackground.style.top = "46px";
    }

    MobileDrawer.className += " bg-dark-gray white pa3 min-h-100";
    MobileDrawer.style.width = "50vw";
    MobileDrawer.style.cursor = "default";

    if (open) {
      MobileDrawer.className += " animate__animated animate__slideInLeft";
    }

    DrawerBackground.onClick = () => {
      setOpen(false);
    };

    MobileDrawer.onClick = (event) => {
      event.stopPropagation();
    };
  } else {
    DrawerBackground.onMouseLeave = () => setHover("");
  }

  return (
    <nav {...{ className }}>
      <PageWidth>
        <div {...DrawerBackground}>
          <div {...MobileDrawer}>
            <div className="ma0">
              <div className="flex-l items-center-l justify-center-l nowrap-l">
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={item.id}
                    className="db no-underline ph3-l"
                    subMenu={{ className: "nt3-l" }}
                    {...{ item, hover, setHover, index }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </PageWidth>
    </nav>
  );
}

export default Menu;
