import React from "react";
import { Loading, PageWidth, Input } from "../components";
import { SearchIcon } from "../svg";
import { useSearchContext } from "./Context";
import NoSearchResults from "./NoSearchResults";

export function SearchForm({ children, edges, loading }) {
  const { filter, setFilter } = useSearchContext();

  return (
    <>
      <PageWidth className="mv4">
        <div className="flex items-center">
          <Input
            type="search"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          />
          <div className="pl3">
            <SearchIcon className="w2 db" />
          </div>
        </div>
      </PageWidth>

      {loading && edges.length === 0 ? (
        <div className="tc">
          <Loading />
        </div>
      ) : edges.length === 0 && (filter || "").length < 3 ? (
        <NoSearchResults />
      ) : (
        children
      )}
    </>
  );
}

export default SearchForm;
