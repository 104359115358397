import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { PageWidth, Social } from "../components";
import { useSettings } from "../hooks";

export function Footer() {
  const { data } = useQuery(QueryFooterMenu, { errorPolicy: "all" });
  const { title, contactAddress, footerText } = useSettings();

  return (
    <footer id="footer" className="relative z-1">
      <div className="bg-primary white pv3">
        <PageWidth className="tc">
          <div className="ttu fw7 f6 mb2">Follow The Key on Social Media</div>
          <Social className="overflow-hidden" />
          <div className="f4 ttu mt2">
            {(data?.menuItems?.nodes || []).map((node) => {
              const isAnchor =
                node?.connectedNode?.node?.__typename === "MenuItem";

              const AnchorTag = isAnchor ? "a" : Link;
              const AnchorProps = {};

              if (isAnchor) {
                AnchorProps.href = node.url;
              } else {
                AnchorProps.to = node.url.replace(/^.*\/\/[^/]+/, "");
              }

              return (
                <AnchorTag
                  key={node.id}
                  className="dib no-underline color-inherit"
                  {...AnchorProps}
                >
                  {node.label} &bull;&nbsp;
                </AnchorTag>
              );
            })}
            {footerText}
          </div>
        </PageWidth>

        <div className="absolute-l top-0-l right-0-l ma3 tc tl-l">
          <div className="secondary ttu f4">{(title || "").toUpperCase()}</div>
          <div className="pre-line">{contactAddress}</div>
        </div>
      </div>
    </footer>
  );
}

const QueryFooterMenu = gql`
  query FooterMenu {
    menuItems(where: { location: FOOTER_MENU }) {
      nodes {
        id
        url
        label
        connectedNode {
          node {
            __typename
          }
        }
      }
    }
  }
`;

export default Footer;
