import { gql, useMutation } from "@apollo/client";
import {
  createContext,
  useContext,
  useReducer,
  useRef,
} from "react";
import formSchema from "./schema";

/**
 * Context for this Form.
 */
const DefaultFormContext = createContext({});

/**
 * The useContext wrapper for the context.
 */
export function useDefaultFormContext() {
  return useContext(DefaultFormContext);
}

/**
 * The provider that sets up the actions, variables, and reducer.
 *
 * @param {Object} props The React props.
 * @param {Array|Object} props.children The React children for the element to be rendered.
 * @param {String} props.className The passed className.
 */
export default function DefaultFormProvider({ children, className }) {
  const token = useRef();
  const [state, updateForm] = useReducer(RecruitmentReducer, initialState);

  // Mutation action with error and completion handlers.
  const [mutation, { loading }] = useMutation(FormMutation, {
    onCompleted: (data) => {
      const result = Object.values(data)[0];

      if (result.success || process.env.NODE_ENV === "development") {
        updateForm({
          action: "FORM_SUBMITTED",
        });
      } else if (result.errorMessage) {
        updateForm({
          message: result.errorMessage,
        });
      }
    },
    onError: (data) => {
      console.error(data);

      updateForm({ message: data.message || "Error during submission" });
    },
  });

  /**
   * Form submission onClick.
   *
   * Does a check of the form to catch any controls without onChange events.
   * Then displays the error, or starts the mutation if successful.
   */
  const onClick = () => {
    const err = errorCheck(state.form);

    const check = Object.values(err).filter((_e) => !!_e);

    const submission = { ...state.form };

    if (check.length === 0) {
      if (token.current?.get) {
        token.current.get(
          (t) => {
            mutation({
              variables: { form: { ...submission, gToken: t } },
            });
          },
          {
            onError: () => {
              updateForm({ message: "Recaptcha failed" });
            },
          }
        );
      } else {
        mutation({ variables: { form: submission } });
      }
    } else {
      updateForm({ errors: err });
    }
  };

  return (
    <div {...{ className }}>
      <DefaultFormContext.Provider
        value={{
          ...state,
          onClick,
          token,
          mutation,
          updateForm,
          loading,
        }}
      >
        <div>
          {state.message && (
            <div className="mv3 f3 fw7 red">{state.message}</div>
          )}
        </div>

        {children}
      </DefaultFormContext.Provider>
    </div>
  );
}

/**
 * Form Mutation Tag.
 */
const FormMutation = gql`
  mutation defaultFormMutation($form: DefaultFormMutationInput!) {
    defaultFormMutation(input: $form) {
      success
      errorMessage
      clientMutationId
    }
  }
`;

/**
 * Creates the inital state from the schema.
 */
function buildFormState() {
  const payload = {};
  Object.entries(formSchema).forEach(([id, attrs]) => {
    payload[id] = attrs.type === "checkbox" ? [] : "";
  });

  payload.clientMutationId = btoa(Date.now());

  return payload;
}

/**
 * Returns the errors from the passed state object.
 *
 * @param {Object} formState The form state object.
 */
export function errorCheck(formState) {
  const errors = {};

  Object.entries(formSchema).forEach(([_id, attrs]) => {
    if (attrs.errorMessage) {
      errors[_id] = attrs.valid(formState[_id]) ? false : attrs.errorMessage;
    }
  });

  return errors;
}

/**
 * The Reducer.
 *
 * @param {Object} state The immutable state. Must be returned at the end of the function.
 * @param {Object} action The actions passed through the dispatch function.
 * @param {String} action.action The action name handled via the switch/case.
 * @param {String} action.id The formSchema key that corresponds to the state.form[id].
 * @param {String|Array} action.value The form value passed for the preceeding id. Arrays for checkboxes.
 * @param {Object} action.errors Error object. The keys correspond to the action.id and will be populated with the formSchema[id].errorMessage on error.
 * @param {String} action.message Form message (at the top).
 * @param {Boolean} action.checked action.id checked status
 * @param {Object} action.merge Object to be merged with state.form.
 */
function RecruitmentReducer(
  state,
  { action, id, value, errors, message, checked, merge }
) {
  const stateUpdate = { ...state };

  if (errors !== undefined) {
    stateUpdate.message = "";
    stateUpdate.errors = errors;
  }

  if (message !== undefined) {
    stateUpdate.message = message;
  }

  switch (action) {
    case "MERGE":
      return { ...stateUpdate, form: { ...stateUpdate.form, ...merge } };
    case "CLEAR_MESSAGE":
      return { ...stateUpdate, message: "" };
    case "CLEAR_FORM":
      return { ...stateUpdate, form: initialState.form, errors: {} };
    case "FORM_SUBMITTED":
      return {
        ...stateUpdate,
        form: initialState.form,
        errors: {},
        message: <span className="green">Thank you for your submission</span>,
      };
    default:
      break;
  }

  if (id) {
    switch (checked) {
      case true:
        if (!stateUpdate.form[id].includes(value)) {
          if (formSchema[id].type === "radio") {
            stateUpdate.form = {
              ...stateUpdate.form,
              [id]: value,
            };
          } else {
            stateUpdate.form = {
              ...stateUpdate.form,
              [id]: [...stateUpdate.form[id], value],
            };
          }
        }
        break;
      case false:
        stateUpdate.form = {
          ...stateUpdate.form,
          [id]: stateUpdate.form[id].filter((item) => item !== value),
        };
        break;
      default:
        stateUpdate.form = { ...stateUpdate.form, [id]: value };
        break;
    }

    return {
      ...stateUpdate,
      errors: {
        ...stateUpdate.errors,
        [id]:
          !formSchema[id].valid || formSchema[id].valid(stateUpdate.form[id])
            ? false
            : formSchema[id].errorMessage,
      },
    };
  }

  return stateUpdate;
}

/**
 * @constant {Object} initialState The initialState for the reducer.
 */
const initialState = {
  form: buildFormState(),
  errors: {},
  message: false,
  messageEle: {},
};
