import { useMemo } from "react";
import { useNodeContext } from "../node";

export function useBannerAd() {
  const { acf } = useNodeContext();

  const link = useMemo(() => {
    return acf?.bannerAd?.bannerLink;
  }, [acf]);

  const { src, alt } = useMemo(() => {
    const src = acf?.bannerAd?.bannerImage?.sourceUrl;
    const alt = acf?.bannerAd?.bannerImage?.altText;

    return { src, alt };
  }, [acf]);

  return { link, src, alt };
}
