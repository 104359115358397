import React, { useState, useEffect } from "react";
import { AuthorProfile, PageWidth } from "../components";
import { useAppContext, useUiContext } from "../Context";
import { useNodeContext } from "../node";
import { SidebarAd } from "../ads";
import { gql, useQuery } from "@apollo/client";

export function SidebarContent() {
  const { data } = useQuery(IssuQuery);
  const issuu = data?.acfGroups?.homeFields?.issuu;
  const social = data?.acfGroups?.homeFields?.social;

  return (
    <div className="pl4-l pl0">
      <AuthorContent />

      {social && (
        <div className="mb3">
          <div dangerouslySetInnerHTML={{ __html: social }} />
        </div>
      )}

      <SidebarAd />

      <div className="bg-dark-gray">
        {issuu && <div dangerouslySetInnerHTML={{ __html: issuu }} />}
      </div>
    </div>
  );
}

function AuthorContent() {
  const { node } = useNodeContext();
  const { title } = useAppContext();
  const { isMobile } = useUiContext();
  const [height, setHeight] = useState(0);

  const { author } = node;

  title.current.setTitleHeight = setHeight;

  useEffect(() => {
    if (title.current.titleHeight) {
      setHeight(title.current.titleHeight);
    }
  }, [setHeight, title]);

  const props = {
    style: {},
    className: "tc relative z-1 overflow-hidden",
  };
  props.style.minHeight = `${!!author ? height || 0 : 0}px`;

  if (props.style.minHeight !== "0px") {
    props.className += " mb3 mt4 mt0-l";
  }

  if (isMobile === true) {
    props.style = {};
  }

  return (
    <div {...props}>
      <AuthorProfile className="tl db-l dib" {...{ author, height }} />
    </div>
  );
}

export function Sidebar({ children, className, sideBarContent }) {
  return (
    <PageWidth {...{ className }}>
      <div className="flex-l items-stretch-l">
        <div className="w-two-thirds-l">{children}</div>
        <div className="w-third-l">
          {sideBarContent ? sideBarContent : <SidebarContent />}
        </div>
      </div>
    </PageWidth>
  );
}

const IssuQuery = gql`
  query {
    acfGroups(uri: "/") {
      homeFields {
        issuu
        social
      }
    }
  }
`;
