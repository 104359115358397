import React from "react";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { PageWidth, Title, Button, PageLoading } from "../components";
import { CreatePaginationQuery, MinimumFragment, Seo, useNode } from "../node";
import { useSettings } from "../hooks";

export function Archives() {
  const Query = gql`
    query ArchiveQuery(
      $first: Int
      $last: Int
      $after: String
      $before: String
    ) {
      ${CreatePaginationQuery("posts", "...MinimumFragment", "status: PUBLISH")}
    }
    ${MinimumFragment}
  `;

  const { title } = useSettings();

  const { edges, pageInfo, fetchMore, loading, called } = useNode({
    query: Query,
    mainQuery: true,
    perPage: 100,
  });

  const { hasNextPage } = pageInfo;

  if (!called) {
    return <PageLoading />;
  }

  return (
    <>
      <Seo title={`Archives - ${title || ""}`} />
      <Title>Archives</Title>
      <PageWidth className="serif">
        {edges.map(({ node: { id, uri, title, date } }) => (
          <div key={id}>
            <Link to={uri} className="color-inherit">
              {date}: {title}
            </Link>
          </div>
        ))}

        {hasNextPage && (
          <div className="mt4 tc">
            <Button
              {...{ loading }}
              onClick={() => {
                fetchMore({
                  variables: {
                    after: pageInfo.endCursor,
                  },
                  notifyOnNetworkStatusChange: true,
                });
              }}
            >
              Load More
            </Button>
          </div>
        )}
      </PageWidth>
    </>
  );
}
