import React, { createContext, useContext, useState } from "react";

export const SearchContext = createContext({});
export function useSearchContext() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const [filter, setFilter] = useState("");

  return (
    <SearchContext.Provider value={{ filter, setFilter }}>
      {children}
    </SearchContext.Provider>
  );
}
