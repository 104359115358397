import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [viewerId, setViewerId] = useState(0);
  const [capabilities, setCapabilities] = useState();

  return (
    <AuthContext.Provider
      value={{
        capabilities,
        setCapabilities,
        viewerId,
        setViewerId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
