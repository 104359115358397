import React from "react";
import { Link } from "react-router-dom";

export const AuthorProfile = ({ author, className: classNameProp }) => {
  if (!author) {
    return null;
  }

  const {
    description,
    name,
    firstName,
    avatar,
    uri,
    fields: { social },
  } = author.node;

  const SocialProps = { className: "db color-inherit no-underline f5" };
  const SocialWrap = social.url ? "a" : "div";
  if (social.url) {
    SocialProps.href = social.url;
  }

  const className = `${classNameProp} mb3`;

  return (
    <div {...{ className }}>
      <div className="flex h-100">
        <div className="w-third flex-none h-100 relative z-1">
          {avatar?.url && (
            <div className="aspect-ratio aspect-ratio--1x1 br-100 overflow-hidden">
              <img
                className="aspect-ratio--object object-contain"
                src={avatar.url}
                alt="Avatar"
              />
            </div>
          )}
        </div>
        <div className="pl3">
          <div className="f4 lh-solid">{name}</div>
          <SocialWrap {...SocialProps}>{social.label}</SocialWrap>
          <div className="f7 i lh-title">{description}</div>
          <div className="f7 mt1">
            <Link to={uri} className="link color-inherit underline i">
              Read more from {firstName || name}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
