import React from "react";

export function PageWidth({ children, className }) {
  return (
    <div className={`w-100 mw8 ph3 ph0-l center ${className || ""}`}>
      {children}
    </div>
  );
};

export default PageWidth;
