import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../Context";

function PreloadWrap({ uri, children }) {
  const { preloader } = useAppContext();

  useEffect(() => {
    if (preloader.current.addUrl) {
      preloader.current.addUrl(uri);
    }
  }, [uri, preloader]);

  return children;
}

export const Anchor = (p) => {
  const { ...props } = p;
  const { pathname } = useLocation();
  const [hash, setHash] = useState();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }

      setHash(null);
    }
  }, [hash]);

  let origin = null;
  let hashOnPathname = false;

  if (props.href) {
    if (props.href.indexOf("/") === 0 || props.href.indexOf("#") === 0) {
      origin = process.env.REACT_APP_DOMAIN;
    } else {
      ({ origin } = new URL(props.href));
    }

    if (props.href.indexOf(`${pathname}#`) === 0) {
      props.href = props.href.replace(pathname, "");
      hashOnPathname = true;
    }

    if (props.href.indexOf("#") === 0) {
      hashOnPathname = true;
    }

    if (props.href.indexOf("#") !== -1) {
      const localHash = props.href.substring(
        props.href.indexOf("#") + 1,
        props.href.length
      );

      const prevOnClick = props.onClick ? props.onClick : null;

      props.onClick = (e) => {
        setHash(localHash);

        if (prevOnClick) {
          prevOnClick(e);
        }
      };

      if (hashOnPathname) {
        props.className = `${props.className || ""} pointer`;
        return <span {...props} />;
      }
    }
  }

  if (origin.indexOf(process.env.REACT_APP_DOMAIN) === 0) {
    let to = props.href.replace(origin, "");

    if (to.indexOf("#") === 0) {
      to = "/" + to;
    }

    return (
      <PreloadWrap uri={to}>
        <Link {...{ to }} {...props} href={null} />
      </PreloadWrap>
    );
  }

  if (!props.target && props.href.indexOf("tel") !== 0) {
    props.target = "_new";
  }

  if (!props.rel) {
    props.rel = "noopener nofollow";
  }

  // eslint-disable-next-line
  return <a {...props} />;
};

export default Anchor;
