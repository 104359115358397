import React from "react";

export function NoSearchResults() {
  return (
    <div className="gray tc f4 f3-l mv4">
      This is where the search results will be. Use the form above to search.
    </div>
  );
}

export default NoSearchResults;
