import React, { useState } from "react";
import { Anchor } from "../components";
import { useSettings, useStickyPosts } from "../hooks";

export function Logo({ h1 }) {
  const [preload, setPreload] = useState();
  const { title, logo } = useSettings();
  const LogoContainer = h1 ? "h1" : "div";

  const onMouseOver = () => {
    setPreload(true);
  };

  return (
    <LogoContainer className="ma0 lh-title f3">
      <Anchor
        href="/"
        className="no-underline db primary fw7"
        {...{ onMouseOver }}
      >
        {logo ? (
          <img src={logo} alt={title} className="w5-l w-100 db" />
        ) : (
          title
        )}

        {preload && <Preload />}
      </Anchor>
    </LogoContainer>
  );
}

function Preload() {
  useStickyPosts();
  return null;
}

export default Logo;
