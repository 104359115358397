import React from "react";
import { ApolloClient, ApolloProvider as Provider, from } from "@apollo/client";
import { cache } from "./Cache";
import { authAfterware, link } from "./Link";

export function ApolloProvider({ children }) {
  const client = new ApolloClient({
    link: from([authAfterware, link]),
    cache,
  });

  return <Provider {...{ client }}>{children}</Provider>;
}
