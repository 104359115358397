import React, { useState } from "react";
import { Link } from "react-router-dom";
import useNode from "./useNode";
import PostContent from "./PostContent";

const Preload = ({ uri }) => {
  useNode({ uri });

  return null;
};

export const Card = ({
  className,
  node: {
    featuredImage,
    title,
    content,
    author: authorProp,
    date,
    uri,
    classifiedField,
  },
}) => {
  const [preload, setPreload] = useState();
  const author = authorProp?.node?.name || "";
  const style = {};

  if (featuredImage) {
    style.backgroundImage = `url("${featuredImage?.node?.medium}")`;
  }

  const { expiration } = classifiedField || {};

  const onMouseOver = () => {
    setPreload(true);
  };

  return (
    <div {...{ className, onMouseOver }}>
      {preload && <Preload {...{ uri }} />}
      <div className="aspect-ratio aspect-ratio--16x9 mb2">
        <div
          className="aspect-ratio--object z-1 bg-gray cover bg-center"
          {...{ style }}
        />
        <Link to={uri} className="absolute absolute--fill z-2 db" />
      </div>
      <div className="ttu i f4 lh-title clamp-2">
        <Link to={uri} className="no-underline link color-inherit">
          {title}
        </Link>
      </div>
      {author && (
        <div className="gray ttu tr f6">
          <span>By {author}</span>
          <span> &bull; </span>
          <span>{date}</span>
        </div>
      )}
      {expiration && (
        <div className="gray ttu tr f6">
          <span>expires </span>
          <span>{expiration}</span>
        </div>
      )}
      <PostContent className="clamp-4 f7 serif" trim>
        {content}
      </PostContent>
    </div>
  );
};

export default Card;
