import React from "react";
import { Valid, Recaptcha } from "../form";

export const schema = {
  yourName: {
    errorMessage: "Please enter a name",
    valid: Valid.NotEmptyString,
    label: "Name",
  },
  email: {
    type: "email",
    errorMessage: "Missing or invalid email.",
    valid: Valid.Email,
    label: "Email",
    onValid: ({ token }) => {
      return <Recaptcha {...{ token }} />;
    },
  },
  phone: {
    type: "tel",
    errorMessage: "Invalid phone number.",
    valid: function (v) {
      return v === "" || Valid.Phone(v);
    },
    label: "Phone",
    required: false,
  },
  message: {
    type: "textarea",
    label: "Message",
  },
};

export default schema;
