import { gql } from "@apollo/client";
import CreatePaginationQuery from "./CreatePaginationQuery";

export const LiteralSeo = `
  title
  metaDesc
  breadcrumbs {
    url
    text
  }
`;


export const FragmentPost = `
  fragment PostFragment on Post {
    id
    databaseId
    uri
    title
    excerpt
    content
    date
    isSticky
    author {
      node {
        id
        firstName
        uri
        name
        description
        avatar {
          url
        }
        fields: userFields {
          social {
            url
            label
          }
        }
      }
    }
    featuredImage {
      node {
        altText
        large: sourceUrl(size: LARGE)
        medium: sourceUrl(size: MEDIUM)
        mediaItemUrl
        id
      }
    }
    seo {
      ${LiteralSeo}
    }
    sidebarAd {
      image {
        id
        alt: altText
        src: sourceUrl(size: MEDIUM)
      }
      link
      disabled
    }
    categories(first: 5) {
      edges {
        node {
          id
          databaseId
          slug
          name
          uri
        }
      }
    }
  }
`;

export const FragmentPage = gql`
  fragment PageFragment on Page {
    id
    databaseId
    uri
    title
    content
    seo {
      ${LiteralSeo}
    }
    sidebarAd {
      image {
        id
        alt: altText
        src: sourceUrl(size: MEDIUM)
      }
      link
      disabled
    }
  }
`;

export const FragmentClassified = gql`
  fragment ClassifiedFragment on Classified {
    id
    databaseId
    uri
    title
    content
    classifiedField {
      expiration
    }
    featuredImage {
      node {
        altText
        large: sourceUrl(size: LARGE)
        medium: sourceUrl(size: MEDIUM)
        mediaItemUrl
        id
      }
    }
    sidebarAd {
      image {
        id
        alt: altText
        src: sourceUrl(size: MEDIUM)
      }
      link
      disabled
    }
    seo {
      ${LiteralSeo}
    }
  }
`;

export const FragmentContentType = gql`
  fragment ContentTypeFragment on ContentType {
    id
    title: graphqlPluralName
    ${CreatePaginationQuery("contentNodes", "...PostFragment ...ClassifiedFragment")}
  }
`;

export const FragmentUserArchive = gql`
  fragment UserArchiveFragment on User {
    id
    name
    ${CreatePaginationQuery("posts", "...PostFragment")}
  }
`;

export const FragmentCategory = gql`
  fragment CategoryFragment on Category {
    id
    databaseId
    slug
    name
    uri
    seo {
      ${LiteralSeo}
    }
    ${CreatePaginationQuery("posts", "...PostFragment")}
  }
`;

export const FragmentTag = gql`
  fragment TagFragment on Tag {
    id
    databaseId
    slug
    name
    uri
    seo {
      ${LiteralSeo}
    }
    ${CreatePaginationQuery("posts", "...PostFragment")}
  }
`;

export const MinimumFragment = gql`
  fragment MinimumFragment on Post {
    id
    databaseId
    uri
    title
    content
    date
  }
`;