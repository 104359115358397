import React from "react";
import { Anchor } from "../components";
import { useSidebarAd } from "./useSidebarAd";

export default function SidebarAd({ className = "mb3" }) {
  const { src, alt, enabled, link } = useSidebarAd();

  if (!enabled) {
    return null;
  }

  if (!src) {
    return null;
  }

  return (
    <div {...{ className }}>
      <Wrap {...{ link }}>
        <img className="w-100 db object-cover" {...{ src, alt }} />
      </Wrap>
    </div>
  );
}

function Wrap({ link, children }) {
  if (link) {
    return <Anchor href={link}>{children}</Anchor>;
  }

  return children;
}
